/* eslint-disable no-console */
import { version } from "../../../package.json";
import { useCallback, useEffect } from "react";

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  const compare = versionsA.map((number, index) => {
    const a = Number(number);
    const b = Number(versionsB.length > index ? versionsB[index] : "");

    if (a !== b) return a > b || isNaN(b);

    return false;
  });

  return !!compare.find((value) => value === true);
};

export const CacheBuster = ({ children }) => {
  const clearCacheWhenNewVersion = useCallback(() => {
    void fetch("/app.json").then(
        (response) => response?.json()
      ).then((appDetails) => {
        if (semverGreaterThan(appDetails.version, version)) {
          caches
            .keys()
            .then((names) => {
              return names.forEach(async (name) => {
                await caches.delete(name);
              });
            })
            .finally(() => window.location.reload());
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    clearCacheWhenNewVersion();
    // Review it each 2 hours
    setInterval(() => clearCacheWhenNewVersion(), 120 * 60 * 1000);
  }, [clearCacheWhenNewVersion]);

  return children;

};
